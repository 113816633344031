<template>
  <div
    class="content-heading col-xl-6 offset-xl-3"
    :class="[centered ? 'text-center' : '']"
  >
    <h2>{{ title }}</h2>
    <div class="py-2" v-if="description">
      {{ description }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
