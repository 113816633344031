<template>
  <div class="offer-form">
    <ContentHeader :title="createOrUpdateText" />
    <div class="card">
      <form @submit.prevent="handleSubmit">
        <div class="card-body pt-5 pb-4">
          <ContentHeading
            :title="$gettext('Job description')"
            :description="
              $gettext(
                'Include as much details as possible, such as responsibilities, tasks, salary and address to attract better candidates.'
              )
            "
            centered
          />
          <div class="row">
            <FormGroupMultiselect
              class="col-md-6"
              field="languages"
              :label="$gettext('Languages')"
              :options="languages"
              :multiple="true"
              v-model="payload.languages"
              required
              @input="handleLanguagesChange"
            />
            <FormGroupMultiselect
              class="col-md-6"
              field="primary_language"
              :label="$gettext('Primary language')"
              :options="payload.languages"
              v-model="payload.primary_language"
              required
              :disabled="payload.languages.length === 0"
            />
          </div>
          <div class="offer-content" v-if="payload.languages.length">
            <div class="row" v-if="payload.languages.length > 1">
              <div class="col-md-8 col-xl-9">
                <b-form-radio-group
                  class="editing-language-switch"
                  v-model="editingLanguage"
                  :options="payload.languages"
                  buttons
                  button-variant="link"
                  text-field="label"
                ></b-form-radio-group>
              </div>
              <div class="col-md-4 col-xl-3 text-right">
                <TranslateWithGoogleButton
                  :disabled="disableTranslateButton"
                  class="btn-block d-md-inline-block"
                  @click.native="confirmTranslateOfferContent"
                />
              </div>
            </div>
            <div class="row">
              <FormInput
                class="col-md-12"
                v-model="payload.contents[editingLanguage].title"
                :label="$gettext('Title')"
                :field="`offer_contents.${editingLanguage}.title`"
                required
              />
              <Editor
                class="col-md-12"
                v-model="payload.contents[editingLanguage].description"
                :label="$gettext('Description')"
                :field="`offer_contents.${editingLanguage}.description`"
                required
              />
            </div>
            <div class="row">
              <div
                class="col-md-12"
                v-if="showOfferMustBeTranslatedNotification"
              >
                <div
                  class="alert alert-info d-flex justify-content-between align-items-center mt-1"
                >
                  {{
                    $gettext(
                      "Make your job posting visible in multiple languages with our translation tool."
                    )
                  }}
                  <TranslateWithGoogleButton
                    @click.native="confirmTranslateOfferContent"
                    class="my-0"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr class="my-4 border-light" />
          <div class="row">
            <div class="col-md-6">
              <FormGroupMultiselect
                field="company"
                :label="$gettext('Company')"
                :options="companies"
                v-model="payload.company"
                option-label="name_fr"
                track-by="id"
                @input="handleCompanyChange"
                required
                class="mb-1"
              />
              <div class="row">
                <FormGroupCheckbox
                  class="col-md-6"
                  :label="$gettext('Confidential')"
                  field="is_confidential"
                  v-model="payload.is_confidential"
                />
              </div>
            </div>
            <div class="col-md-6">
              <FormGroupGmapAutocomplete
                :label="$gettext('Job location')"
                class="mb-1"
                field="address"
                v-model="payload.formatted_address"
                :placeholder="$gettext('Enter location')"
                required
              />
              <div class="row">
                <FormGroupCheckbox
                  class="col-md-6"
                  :label="$gettext('Remote')"
                  field="remote"
                  v-model="payload.remote"
                />
                <FormGroupCheckbox
                  class="col-md-6"
                  :label="$gettext('Confidential Location')"
                  field="is_confidential_location"
                  v-model="payload.is_confidential_location"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <FormGroupNocJobTitle
              class="col-md-12"
              field="noc_job_title"
              :label="$gettext('National Occupational Classification (NOC)')"
              v-model="payload.noc_job_title"
              required
            />
          </div>
          <div class="row">
            <FormGroupMultiselect
              class="col-md-4"
              field="categories"
              :label="$gettext('Categories')"
              :options="categoryOptions"
              v-model="payload.categories"
              option-label="label"
              option-description="description"
              :strong-option-label="true"
              track-by="id"
              :multiple="true"
              required
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="specializations"
              :label="$gettext('Specializations')"
              :options="specializationGroupedOptions"
              v-model="payload.specializations"
              option-label="label"
              track-by="id"
              :multiple="true"
              group-values="options"
              group-label="label"
              :disabled="payload.categories.length === 0"
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="job_type"
              :label="$gettext('Job type')"
              :options="jobTypes"
              v-model="payload.job_type"
              option-label="label"
              track-by="id"
              :multiple="true"
            />
          </div>
          <div class="row">
            <FormInputNumber
              class="col-md-4"
              :label="$gettext('Salary (min)')"
              field="salary_min"
              step="0.01"
              min="0"
              v-model="payload.salary_min"
            />
            <FormInputNumber
              class="col-md-4"
              :label="$gettext('Salary (max)')"
              field="salary_max"
              step="0.01"
              min="0"
              v-model="payload.salary_max"
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="salary_type"
              :label="$gettext('Rate')"
              :options="salaryTypeOptions"
              v-model="payload.salary_type"
            />
          </div>
          <div class="row">
            <FormInput
              type="date"
              field="start_date"
              :label="$gettext('Start date')"
              class="col-md-4 datepicker"
              v-model="payload.start_date"
            />
            <FormInput
              class="col-md-4"
              :label="$gettext('Internal reference number')"
              field="internal_reference_number"
              v-model="payload.internal_reference_number"
            />
          </div>
          <hr class="my-5 border-light" />
          <ContentHeading
            :title="$gettext('Requirements')"
            :description="
              $gettext(
                'Add the necessary requirements to make your job offer visible to the right candidates'
              )
            "
            centered
          />
          <div class="row">
            <FormGroupMultiselect
              class="col-md-4"
              field="experiences"
              :label="$gettext('Experience')"
              :options="experiences"
              v-model="payload.experiences"
              option-label="label"
              track-by="id"
              :multiple="true"
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="educations"
              :label="$gettext('Education')"
              :options="educations"
              v-model="payload.educations"
              option-label="label"
              track-by="id"
              :multiple="true"
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="certifications"
              :label="$gettext('Certifications')"
              :options="certificationOptions"
              v-model="payload.certifications"
              option-label="label"
              track-by="id"
              :multiple="true"
            />
            <FormGroupMultiselect
              class="col-md-4"
              field="skills"
              :label="$gettext('Skills')"
              :options="skillOptions"
              v-model="payload.skills"
              option-label="label"
              track-by="id"
              :multiple="true"
            />
          </div>
          <hr class="my-5 border-light" />
          <ContentHeading
            :title="$gettext('Specifications')"
            :description="$gettext('Determine members and application method')"
            centered
          />
          <div class="row mb-4">
            <FormGroupMultiselect
              class="col-md-12"
              field="recruiters"
              :label="
                $gettext(
                  'Team members that will receive email notifications for new applicants'
                )
              "
              :options="userOptions"
              v-model="payload.recruiters"
              option-label="full_name"
              track-by="id"
              :multiple="true"
            />
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group :label="$gettext('External redirection link')">
                <b-form-radio-group
                  v-model="payload.apply_external"
                  :options="externalLinkOptions"
                  stacked
                />
              </b-form-group>
            </div>
          </div>
          <div v-if="payload.apply_external">
            <div
              class="row"
              v-for="{ label, value } in payload.languages"
              :key="value"
            >
              <FormInput
                class="col-md-12"
                v-model="payload.contents[value].apply_external_link"
                :label="
                  $gettextInterpolate('External link (%{label})', { label })
                "
                :field="`offer_contents.${value}.apply_external_link`"
              />
            </div>
          </div>
          <hr class="mt-5 mb-3 border-light" />
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
              <router-link :to="{ name: 'offers' }" class="btn btn-danger"
                ><translate>Cancel</translate></router-link
              >
              <SubmitButton
                :label="$gettext('Save')"
                type="submit"
                class="btn btn-primary pmd-ripple-effect pmd-btn-raised"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Editor from "@/components/FormGroups/Editor";
import FormGroupMultiselect from "@/components/FormGroups/FormGroupMultiselect";
import FormGroupNocJobTitle from "@/components/FormGroups/FormGroupNocJobTitle";
import FormInput from "@/components/FormGroups/FormInput";
import FormGroupGmapAutocomplete from "@/components/FormGroups/FormGroupGmapAutocomplete";
import FormGroupCheckbox from "@/components/FormGroups/FormGroupCheckbox";
import SubmitButton from "@/components/SubmitButton";
import ContentHeader from "@/components/ContentHeader";
import { parseNocJobTitle } from "@/store/modules/nocJobTitles";
import { scrollToFirstElement } from "@/utils/scrollTo";
import ContentHeading from "@/components/ContentHeading";
import TranslateWithGoogleButton from "@/components/Offers/TranslateWithGoogleButton";
import { msgBoxConfirm } from "@/utils/modal";
import { translateText } from "@/utils/translation";
import { salaryTypes } from "@/enums/salaryTypes";
import FormInputNumber from "@/components/FormGroups/FormInputNumber.vue";

const sortByLabel = (a, b) => a.label.localeCompare(b.label);

// @todo this code is awful, rethink, redo.
export default {
  components: {
    FormInputNumber,
    TranslateWithGoogleButton,
    ContentHeading,
    ContentHeader,
    SubmitButton,
    FormGroupCheckbox,
    FormGroupGmapAutocomplete,
    FormGroupMultiselect,
    FormGroupNocJobTitle,
    FormInput,
    Editor
  },
  props: {
    offerId: {
      type: [String, Number],
      required: false
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.$store.dispatch("companies/fetchOptions");
      if (!vm.$store.getters["companies/hasOptions"]) {
        vm.$toast.info(
          vm.$gettext("You need a company in order to publish an offer.")
        );
        next({ name: "companies" });
      } else {
        next();
      }
    });
  },
  created() {
    const promises = [this.fetchAttributes(), this.fetchUserOptions()];
    if (this.isUpdate) {
      promises.push(
        this.fetchOne(this.offerId).then(() => {
          this.editingLanguage = this.offer.languages[0];
          Object.assign(this.payload, this.offer);
          this.payload.formatted_address = this.offer.location.address;
          this.languages
            .filter(({ value }) => this.payload.contents[value] === undefined)
            .forEach(
              ({ value }) =>
                (this.payload.contents[value] = { title: "", description: "" })
            );

          this.payload.categories = this.offer.offer_attributes.category || [];
          this.payload.specializations =
            this.offer.offer_attributes.specialization || [];
          this.payload.certifications =
            this.offer.offer_attributes.certification || [];
          this.payload.experiences =
            this.offer.offer_attributes.experience || [];
          this.payload.educations = this.offer.offer_attributes.education || [];
          this.payload.job_type = this.offer.offer_attributes.schedule || [];
          this.payload.skills = this.offer.offer_attributes.skill || [];
          this.payload.primary_language = this.languages.find(
            ({ value }) => this.offer.primary_language === value
          );
          this.payload.languages = this.languages.filter(({ value }) =>
            this.offer.languages.includes(value)
          );
          this.payload.salary_type =
            this.salaryTypeOptions.find(
              type => type.value === this.offer.salary_type
            ) || undefined;
          this.parseOfferNocJobTitle();
        })
      );
    } else {
      this.payload.recruiters.push(this.user);
    }
    Promise.all(promises).finally(this.stopFetching);
  },
  data: () => ({
    payload: {
      language: "bilingual",
      primary_language: {},
      languages: [],
      place: {},
      formatted_address: "",
      company: {},
      contents: {
        fr: {
          title: "",
          description: "",
          apply_external_link: ""
        },
        en: {
          title: "",
          description: "",
          apply_external_link: ""
        }
      },
      categories: [],
      specializations: [],
      experiences: [],
      certifications: [],
      skills: [],
      educations: [],
      start_date: null,
      salary_min: undefined,
      salary_max: undefined,
      salary_type: undefined,
      job_type: [],
      internal_reference_number: "",
      remote: false,
      is_confidential_location: false,
      is_confidential: false,
      apply_external: false,
      noc_job_title: {},
      recruiters: []
    },
    editingLanguage: null
  }),
  computed: {
    ...mapGetters("companies", { companies: "options" }),
    ...mapGetters("offers", { offer: "item" }),
    ...mapGetters("teamUsers", { userOptions: "options" }),
    ...mapGetters("offerAttribute", [
      "categories",
      "jobTypes",
      "specializations",
      "experiences",
      "certifications",
      "educations",
      "skills"
    ]),
    ...mapGetters("me", ["lg", "user"]),
    ...mapGetters("form", ["hasError"]),
    isUpdate() {
      return this.offerId !== undefined;
    },
    createOrUpdateText() {
      return this.isUpdate
        ? this.$gettext("Update offer")
        : this.$gettext("Create offer");
    },
    externalLinkOptions() {
      return [
        {
          text: this.$gettext(
            "Recommanded : let applicants apply with their personal email"
          ),
          value: false
        },
        {
          text: this.$gettext("Redirect applicants to an external website"),
          value: true
        }
      ];
    },
    languages() {
      return [
        { value: "fr", label: this.$gettext("French") },
        { value: "en", label: this.$gettext("English") }
      ];
    },
    showOfferMustBeTranslatedNotification() {
      return (
        this.payload.languages.length === 1 &&
        this.payload.contents[this.editingLanguage].title.length &&
        this.payload.contents[this.editingLanguage].description.length
      );
    },
    disableTranslateButton() {
      return !(
        this.payload.contents[this.editingLanguage].title.length &&
        this.payload.contents[this.editingLanguage].description.length
      );
    },
    translationSource() {
      return this.languages.find(lang => lang.value === this.editingLanguage);
    },
    translationTarget() {
      return this.languages.filter(
        lang => lang.value !== this.editingLanguage
      )[0];
    },
    categoryOptions() {
      // sort by label, "Other (id = 31)" category at the end.
      return this.categories
        .map(category => {
          return {
            ...category,
            description: category.jobboards
              .map(jobboard => jobboard.label)
              .join(", ")
          };
        })
        .sort((a, b) => (a.id === 31 ? false : sortByLabel(a, b)));
    },
    categoriesJobboardIds() {
      return this.payload.categories
        .map(category => category.jobboards.map(jobboard => jobboard.id))
        .flat(1);
    },
    specializationsRelatedToCategories() {
      return this.specializations
        .filter(specialization =>
          specialization.jobboards.some(jobboard =>
            this.categoriesJobboardIds.includes(jobboard.id)
          )
        )
        .sort(sortByLabel);
    },
    specializationsUnrelatedToCategories() {
      return this.specializations
        .filter(specialization =>
          specialization.jobboards.some(
            jobboard => !this.categoriesJobboardIds.includes(jobboard.id)
          )
        )
        .sort(sortByLabel);
    },
    specializationGroupedOptions() {
      return [
        {
          label: this.$gettext("Recommended"),
          options: this.specializationsRelatedToCategories
        },
        {
          label: this.$gettext("Others"),
          options: this.specializationsUnrelatedToCategories
        }
      ];
    },
    certificationOptions() {
      return this.certifications.slice().sort(sortByLabel);
    },
    skillOptions() {
      return this.skills.slice().sort(sortByLabel);
    },
    salaryTypeOptions() {
      return salaryTypes.toOptions();
    }
  },
  methods: {
    ...mapActions("offerAttribute", ["fetchAttributes"]),
    ...mapActions("teamUsers", { fetchUserOptions: "fetchOptions" }),
    ...mapActions("offers", ["create", "update", "fetchOne"]),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      let request;
      const payload = Object.assign({}, this.payload);
      payload.languages = this.payload.languages.map(({ value }) => value);
      payload.primary_language = this.payload.primary_language
        ? this.payload.primary_language.value || null
        : null;
      payload.company = this.payload.company
        ? this.payload.company.id || null
        : null;
      payload.noc_job_title = this.payload.noc_job_title
        ? this.payload.noc_job_title.id || null
        : null;
      payload.categories = this.payload.categories.map(({ id }) => id);
      payload.experiences = this.payload.experiences.map(({ id }) => id);
      payload.certifications = this.payload.certifications.map(({ id }) => id);
      payload.skills = this.payload.skills.map(({ id }) => id);
      payload.educations = this.payload.educations.map(({ id }) => id);
      payload.specializations = this.payload.specializations.map(
        ({ id }) => id
      );
      payload.job_type = this.payload.job_type.map(({ id }) => id);
      if (this.payload.salary_type) {
        payload.salary_type = this.payload.salary_type.value;
      }
      if (!payload.apply_external) {
        payload.contents.fr.apply_external_link = null;
        payload.contents.en.apply_external_link = null;
      }
      switch (this.isUpdate) {
        case false:
          request = this.submit(() => this.create(payload));
          break;
        case true:
          request = this.submit(() =>
            this.update({
              id: this.offerId,
              ...payload
            })
          );
          break;
        default:
          break;
      }
      request.catch(this.handleFormErrors);
      if (this.isUpdate) {
        request.then(() =>
          this.$router.push({
            name: "offer-details",
            params: { offerId: this.offerId, updated: true }
          })
        );
      } else {
        request.then(({ data }) => {
          this.$router.push({
            name: "publications",
            params: { offerId: data.id }
          });
        });
      }
    },
    setAddress() {
      this.payload.formatted_address = this.$refs.gmap.$refs.input.value;
    },
    handleCompanyChange() {
      if (this.payload.company && this.payload.company.location) {
        this.payload.formatted_address =
          this.payload.company.location.formatted_address ||
          this.payload.company.location.address;
      }
    },
    parseOfferNocJobTitle() {
      if (
        this.payload.noc_job_title &&
        Object.keys(this.payload.noc_job_title).length !== 0
      ) {
        this.payload.noc_job_title = parseNocJobTitle(
          this.payload.noc_job_title,
          this.lg
        );
      }
    },
    handleLanguagesChange(languages) {
      if (languages.length === 0) {
        this.editingLanguage = null;
      } else if (!languages.find(lang => lang.value === this.editingLanguage)) {
        this.editingLanguage = languages[0].value;
      }
    },
    handleFormErrors() {
      this.payload.languages.every(({ value }) => {
        if (
          this.hasError(`offer_contents.${value}.description`) ||
          this.hasError(`offer_contents.${value}.title`)
        ) {
          this.editingLanguage = value;
          return false;
        }
        return true;
      });
      setTimeout(
        () => scrollToFirstElement(".offer-form form .is-invalid"),
        300
      );
    },
    confirmTranslateOfferContent() {
      const translated = this.$gettext(
        "You are about to translate from %{ source } to %{ target }, switch language tab to inverse language " +
          "source. Attention, this action will override information in %{ target }."
      );
      msgBoxConfirm(
        this.$gettextInterpolate(translated, {
          source: this.translationSource.label.toLowerCase(),
          target: this.translationTarget.label.toLowerCase()
        }),
        {
          title: this.$gettext("Translate Offer"),
          confirmButtonText: this.$gettext("Translate")
        }
      ).then(value => {
        if (value.isConfirmed) {
          this.translateOfferContent();
        }
      });
    },
    translateOfferContent() {
      const translation = {
        title: "",
        description: "",
        source: this.translationSource.value,
        target: this.translationTarget.value
      };
      this.submit(async () => {
        translation.title = await translateText(
          translation.source,
          translation.target,
          this.payload.contents[this.editingLanguage].title
        );
        translation.description = await translateText(
          translation.source,
          translation.target,
          this.payload.contents[this.editingLanguage].description
        );
      }).then(() => {
        this.addLanguageInPayloadLanguages(translation.target);
        this.payload.contents[translation.target].title = translation.title;
        this.payload.contents[translation.target].description =
          translation.description;
        this.editingLanguage = translation.target;
      });
    },
    addLanguageInPayloadLanguages(value) {
      if (!this.payload.languages.find(lang => lang.value === value)) {
        const targetLang = this.languages.find(lang => lang.value === value);
        this.payload.languages.push(targetLang);
      }
    }
  },
  watch: {
    lg() {
      this.parseOfferNocJobTitle();
    },
    "payload.languages": function(newValue) {
      if (
        !newValue
          .map(lang => lang.value)
          .includes(this.payload.primary_language.value)
      ) {
        this.payload.primary_language = {};
      }
    }
  }
};
</script>
