<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :description="
      $gettext(
        'By selecting the right occupation, you ensure your job to be posted on Job Bank, Canada’s national employment service.'
      )
    "
    :aria-required="required"
  >
    <multiselect
      :disabled="isPosting"
      :id="id"
      v-model="inputValue"
      label="title"
      track-by="id"
      :options="items"
      :searchable="true"
      :internal-search="false"
      :multiple="false"
      :loading="!isLoaded"
      preserveSearch
      @search-change="handleSearchChange"
      :placeholder="$gettext('Type to search...')"
    >
      <template slot="afterList">
        <div class="text-center" v-if="hasNextPage && isLoaded">
          <hr class="mb-0" />
          <b-button class="btn-primary" @click="loadMore"
            ><translate>Load More</translate></b-button
          >
        </div>
      </template>
    </multiselect>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  mixins: [FormGroupWithValidationMixin],
  components: { Multiselect },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("nocJobTitles", ["items", "hasNextPage", "isLoaded"]),
    ...mapGetters("me", ["lg"])
  },
  created() {
    this.fetchNocJobTitles();
  },
  methods: {
    ...mapActions("nocJobTitles", [
      "applySearch",
      "fetchNocJobTitles",
      "loadMore"
    ]),
    ...mapMutations("nocJobTitles", ["setCurrentPage"]),
    handleSearchChange(value) {
      this.applySearch(value);
    }
  },
  watch: {
    lg() {
      this.setCurrentPage(1);
      this.fetchNocJobTitles();
    }
  }
};
</script>
