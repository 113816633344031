<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :invalid-feedback="getFieldError(field)"
    :state="!fieldHasError(field)"
    :aria-required="required"
  >
    <b-form-input
      :placeholder="placeholder"
      :disabled="isPosting"
      :id="id"
      type="number"
      v-model="inputValue"
      :step="step"
      :min="min"
      :max="max"
      @wheel="event => event.currentTarget.blur()"
    />
  </b-form-group>
</template>

<script>
import FormGroupWithValidationMixin from "@/components/FormGroups/FormGroupWithValidationMixin";

export default {
  mixins: [FormGroupWithValidationMixin],
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    step: {
      type: [String, Number],
      default: "1"
    },
    min: {
      type: [String, Number],
      default: undefined
    },
    max: {
      type: [String, Number],
      default: undefined
    }
  }
};
</script>
