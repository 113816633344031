<template>
  <button type="button" class="btn btn-primary" :disabled="disabled">
    <i class="fas fa-globe-americas mr-2"></i>
    <translate>Translate with Google</translate>
  </button>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
